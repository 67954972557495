import "../vendor/polyfills";
import "../vendor/sharer";
import "../vendor/sentry";

require("@rails/ujs").start();
// require("turbolinks").start();

window.Rails = Rails;

import "stylesheets/application.css";
import "@fortawesome/fontawesome-free/js/all";

import debounced from 'debounced'
debounced.initialize()

// const importAll = r => r.keys().map(r);
// importAll(require.context("../images", false, /\.(png|jpe?g|svg)$/));
// importAll(require.context("../fonts", false, /\.(ttf)$/));
require.context('../images', true);

document.addEventListener("turbolinks:before-cache", () => {
  // prevents double loading of recaptcha
  if (document.querySelector(".g-recaptcha")) {
    document.querySelector(".g-recaptcha").innerHTML = '';
  }
  // prevents auto scroll on history scroll position for links with anchor due to the turbolinks
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
  }
});
