import * as Sentry from "@sentry/browser";
window.Sentry = Sentry;

try {
  Sentry.init({
    dsn: getMetaValue("sentry")
  });
} catch (error) {
  console.log("unable to start sentry");
}

function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`);
  return element.getAttribute("content");
}
